import React from "react";
import expandMore from "../../assets/images/expand_more.png";
import homeBackground from "../../assets/images/homeBackground.png";
import homeImage from "../../assets/images/homeImage.png";
import Navbar from "./Navbar";

const Home = () => {
  const backgroundImageStyle = {
    backgroundImage: `url(${homeBackground})`,
    backgroundSize: '100% 100%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
  };

  const handleScroll = () => {
    const contactElement = document.getElementById("contact");

    if (contactElement) {
      window.scrollTo({
        top: contactElement.offsetTop,
        behavior: 'smooth',
      });
    }
  };

  return (
    <div style={backgroundImageStyle}>
      <Navbar />
      <div className="home-banner-container">
        <div className="home-text-section">
          <h1 className="primary-heading">
            Unlock Your Potential with Lillup
          </h1>
          <p className="primary-text">
            Where learning meets opportunity - Empowering growth for individuals and companies.
          </p>
          <button className="primary-button" onClick={handleScroll}>
            Learn more
          </button>
        </div>
        <div className="home-image-section">
          <img src={homeImage} alt="" />
        </div>
      </div>
      <div className="scroll-container">
        <button style={{ cursor: "pointer" }} onClick={handleScroll}>
          <span className="scroll-text">Scroll</span>
          <div className="scroll-icon-container">
            <img src={expandMore} alt="" style={{ marginTop: "1px" }} />
            <img src={expandMore} alt="" />
            <img src={expandMore} alt="" />
          </div>
        </button>
      </div>
    </div>
  );
};

export default Home;
