import React from 'react';
import '../../index.css';  // Make sure Tailwind CSS is included in your index.css
// import MacBookImage from '../../assets/images/MacBook Air 13.png';
import MacBookImage from '../../assets/images/MacBook Pro 16.png';
import verfication from '../../assets/images/verification.png';
import savings from '../../assets/images/savings.png';
import talent from '../../assets/images/talent.png';
// import backgroundImg from '../../assets/images/image21.png';

const RefineTalent = () => {
  // const backgroundImageStyle = {
  //   backgroundImage: `url(${backgroundImg})`,
  //   backgroundSize: '100% 100%',
  //   backgroundRepeat: 'no-repeat',
  //   backgroundPosition: 'center center',
  // };

  return (
    <div className="flex items-center justify-center h-fit" >
      <div className="w-fit mx-auto flex justify-center items-stretch space-x-2">
      <div className="flex flex-col flex-grow space-y-4">
          <div className='text-center'>
            <p className='primary-heading mb-2'>Refine How You Find Talent</p>
            <p className='primary-text mb-4'>Acquire the right skills for your business, effortlessly</p>
            <p className='text-l mb-4'>Revolutionise hiring with Lillup, Unearth verified talent quickly, cutting costs and streamlining recruitment.</p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-2 ">
            <div className="border border-red-600 shadow-lg p-2 rounded-lg w-64 grid grid-rows-2">
              <div className='float-left'>
                <img src={verfication} alt="" className="mb-2 w-32 h-32" />
              </div>
              <div className="text-left">
                <span className="block primary-text mb-2">Skill Verification</span>
                <span className="block">Find confidently with blockchain-secured credentials.</span>
              </div>
            </div>
            <div className="border border-red-600 shadow-lg p-2 rounded-lg w-64 grid grid-rows-2">
              <div className='float-left'>
                <img src={savings} alt="" className="mb-2 w-32 h-32" />
              </div>
              <div className="text-left">
                <span className="block primary-text mb-2">Smart Savings</span>
                <span className="block">Efficient talent matches reduce recruitment expenses.</span>
              </div>
            </div>
            <div className="border border-red-600 shadow-lg p-2 rounded-lg w-64 grid grid-rows-2">
              <div className='float-left'>
                <img src={talent} alt="" className="mb-2 w-32 h-32" />
              </div>
              <div className="text-left">
                <span className="block primary-text mb-2">Global Talent</span>
                <span className="block">Tap into rich, worldwide talent reservoir.</span>
              </div>
            </div>
          </div>
          <div className="mt-4 pt-6 text-center flex justify-center">
            <a href="https://www.acellus.com/for-your-school/video/Acellus_Writing_Tutor.mp4" className="primary-button w-56">Connect with Talent</a>
          </div>
        </div>
        <div className="flex-none">
          <img
            decoding="async"
            width="537"
            src={MacBookImage}
            alt=""
            sizes="(min-width: 0px) and (max-width: 480px) 480px, (min-width: 481px) 768px, 100vw"
          />
        </div>

      </div>
    </div>
  );
};

export default RefineTalent;
