import React from 'react';
import '../../index.css';  // Make sure Tailwind CSS is included in your index.css
import backgroundImg from '../../assets/images/image3.png';
import LinkedinImage from "../../assets/images/linkedin.png";
import TwitterXImage from '../../assets/images/twitterx.png';
import instagramImage from '../../assets/images/instagram.png';

const Footer = () => {
  const backgroundImageStyle = {
    backgroundImage: `url(${backgroundImg})`,
    backgroundSize: '100% 100%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
  };
  return (
    <div className="flex items-center p-8 pt-24" style={backgroundImageStyle}>
      <div className="flex-shrink-0 w-3/6 flex space-x-4">
        <span>© 2024 Lillup. All rights reserved.</span>
      </div>
      <div className="flex-shrink-0 w-2/5">
        <div>
          <span className='px-8'><a href="#footer">Terms and Conditions</a></span>
          <span className='px-8'><a href="#footer">Privacy Policy</a></span>
          <span className='px-8'><a href="#footer">Contact</a></span>
        </div>
      </div>
      <div className="flex-shrink-0 flex space-x-4">
        <a href="https://twitter.com/LillupWorkplace" target="__blank" className="duration-500 ease-in-out hover:scale-110 hover:transition-all w-8">
          <img src={TwitterXImage} alt="twitter icon" />
        </a>
        <a href="https://www.linkedin.com/company/lillup/" target="__blank" className="duration-500 ease-in-out hover:scale-110 hover:transition-all w-8">
        <img src={LinkedinImage} alt="linkedin icon" />
        </a>
        <a href="https://www.instagram.com/lillup_ampersand/" target="__blank" className="duration-500 ease-in-out hover:scale-110 hover:transition-all w-8">
        <img src={instagramImage} alt="instagram icon" />
        </a>
      </div>
    </div>
  );
};

export default Footer;
