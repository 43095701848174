import React from "react";

const Contact = () => {
  return (
    <div id="contact" className="contact-page-wrapper">
      <div className="w-[680px] h-[213px] flex-col justify-start items-center gap-10 inline-flex">
        <div className="w-[680px] text-center"><span style={{ fontSize: '1.5rem', fontWeight: 'bold', fontFamily: 'sans-serif', color: '#374151' }}>Lillup combines advanced technology with personalised experiences to bridge the gap between education and employment.<br /><br /></span><span style={{ fontSize: '1rem', fontWeight: 'semibold', fontFamily: 'sans-serif', color: '#374151' }}>Join the revolution in career development and talent acquisition:</span></div>
        <div className="self-stretch h-[39px] justify-start items-center gap-5 inline-flex">
          <div className="grow shrink basis-0 h-[40px] flex-col justify-start items-start gap-4 inline-flex">
            <input
              className="self-stretch grow shrink basis-0 flex-col justify-center items-start gap-1 flex px-4 py-[8px] bg-neutral-100 rounded-[10px] text-base font-normal font-['sans-serif']"
              type="text"
              placeholder="Enter your email address"
              style={{ backgroundColor: '#f2f2f2', color: '#333' }}
            />
          </div>
          <div className="w-[130px] self-stretch px-[30px] py-[17px] bg-red-600 rounded-[10px] justify-center items-center gap-2.5 flex">
            <button className="primary-button">Subscribe</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
