/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Logo from "../../assets/images/logo.svg";

const Navbar = () => {

  return (
    <nav>
      <div className="nav-logo-container">
        <img src={Logo} alt="" />
      </div>
      <div className="navbar-links-container">
        <a href="">For individuals</a>
        <a href="">For Companies</a>
        <a href="#contact">Stay Updated</a>
      </div>
    </nav>
  );
};

export default Navbar;
